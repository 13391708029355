export interface FAQ {
  question: string;
  answer: string;
}
export const FAQs: FAQ[] = [
  {
    question: "1/ When and where is the wedding?",
    answer:
      "The location will be at the Island Art & Taste - Gallery venue. The ceremony will begin at 6PM, following that we'll have a cocktail hour which includes a traditional tea creremony, then the reception where there will be plenty of food and drinks to continue the celebration.",
  },
  {
    question: "2/ What should I wear?",
    answer:
      "The dress code is cocktail attire. Think a suit or blazer combo for the gents and something cute and colorful for the ladies. As the evening may get cooler, consider bringing a light jacket or wrap. We definitely recommend comfrotable shoes as there will be plenty of dancing.",
  },
  {
    question: "3/ What about gifts?",
    answer:
      "If it is your wish to bless us with a gift, we would gladly appreciate a contribution to our wishing well.",
  },

  {
    question: "4/ What is the best way to travel to Greece?",
    answer:
      "Most international guests will likely fly into Athens International Airport (ATH). From there, you can reach Island Art & Taste by car, taxi, or public transportation. We recommend booking flights early, especially during the peak travel season.",
  },
  {
    question: "5/ Do I need a visa to travel to Greece?",
    answer:
      "Citizens of the European Union, Australia, the United States, Malaysia, and many other countries do not require a visa for short stays in Greece. However, please check with your local embassy or consulate for the most up-to-date visa requirements.",
  },
  {
    question: "6/ What's the weather like in Greece in May?",
    answer:
      "May in Greece is pretty much perfect - warm and sunny but not scorching, usually around 20-25°C (68-77°F). Evening temperatures can drop a bit, so pack a light wrap or jacket but we suggest you keep on dancing to keep warm.",
  },
  {
    question: "7/ What is the local currency and will I need cash?",
    answer:
      "The local currency is the Euro (€). Credit and debit cards are widely accepted, but it’s a good idea to carry some cash, especially for small purchases or in more remote areas.",
  },
  {
    question: "8/ How can we contact you?",
    answer:
      "You can contact us on Messenger or via email at dimoandkimo@gmail.com :)",
  },
];
